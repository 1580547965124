#Home {
  background-image: url("../../assets/photos/33.jpg");
  position: fixed;
  min-width: 100%;
  min-height: 100%;
  background-size: cover;
  background-position: center;
}

.homeText {
  font-weight: "bold";
  color: honeydew;
  font-size: 100px;
  display: flex;
  align-items: center;
  height: 200px;
  margin-top: 100px;
  margin-left: 100px;
  position: fixed;
}
